import React from "react";
import { Row, Col, Button } from "reactstrap";
import classes from "./PreviewEmail.module.css";


import ParcoursContext from "../../../../Context/ParcoursContext";
function PreviewEmail({ message, url }) {
  const {  image, backgroundColor } =
    React.useContext(ParcoursContext);

  return (
    <div style={{ minHeight: "100%" }}>
      <h6> Visuel:</h6>
      <div className={classes.contain}>
        <Row
          className={classes.header}
          style={{ backgroundColor: backgroundColor }}
        >
          <Col>
            <h2 className={classes.title}>Devis</h2>
            {image && <img className={classes.head} alt="logo" src={image} />}
          </Col>
        </Row>

        <Row className={classes.rowemail}>
          <Col>
            <p dangerouslySetInnerHTML={{ __html: message }}></p>
          </Col>
        </Row>
        <Row className={classes.rowbtn}>
          <Col className={classes.btn}>
            <Button size="lg" block style={{ backgroundColor: "red" }}>
              <img
                src="https://sdn.as-solution.cloud.geoprod.com/static/uploads/cmp/908499056/1664187072.75361__arrow.png"
                alt="icon"
                style={{
                  height: "1rem",
                  width: "1.5rem",
                  paddingRight: "0.5rem",
                  marginBottom: "0.22rem",
                }}
              />
              DEVIS
            </Button>
          </Col>
        </Row>
        <Row className={classes.rowtext}>
          <Col className={classes.text}>
            <p>
              Je reste à votre entière disposition pour tout complément
              d'informations. Je vous remercie de la confiance que vous nous
              témoignez, en espérant avoir répondu à vos attentes. Cordialement,
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PreviewEmail;
