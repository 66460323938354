import React from "react";

const BackgroundImage = (props) => {
  const { color } = props;
  return (
    <div style={{ overflowY: "hidden" }}>
      <svg
        version="1.0"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1920 1080"
        style={{
          zIndex: "-1",
          position: "fixed ",
        }}
        // xml:space="preserve"
      >
        <path
          style={{ fill: color ? color : "#e00000" }}
          d="M1967.9,633.8l-671.7-493.4c-35.5-26-85.8-18.3-111.8,17.1L726.1,781.4c-26.3,34.3-75.8,41.5-110.8,15.8
	L-48.6,309.5l-64.9,88.4c-26,35.5-18.3,85.8,17.1,111.8l671.7,493.4c35.5,26,85.8,18.3,111.8-17.1l458.2-623.9
	c26.3-34.3,75.8-41.5,110.8-15.8l663.9,487.7l64.9-88.4C2011.1,710.2,2003.4,659.9,1967.9,633.8z"
        />
        <g>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="343"
            y1="571.8156"
            x2="1543"
            y2="571.8156"
          >
            <stop
              offset="0"
              style={{ stopColor: "#FFFFFF", stopOpacity: "5.000000e-02" }}
            />
            <stop
              offset="4.394592e-02"
              style={{ stopColor: "#F8F8F8", stopOpacity: "6.140047e-02" }}
            />
            <stop
              offset="0.1057"
              style={{ stopColor: "#E6E6E6", stopOpacity: "7.742382e-02" }}
            />
            <stop
              offset="0.178"
              style={{ stopColor: "#C8C8C8", stopOpacity: "9.618902e-02" }}
            />
            <stop
              offset="0.2584"
              style={{ stopColor: "#9E9E9E", stopOpacity: "0.117" }}
            />
            <stop
              offset="0.3443"
              style={{ stopColor: "#696969", stopOpacity: "0.1393" }}
            />
            <stop
              offset="0.3855"
              style={{ stopColor: "#4D4D4D", stopOpacity: "0.15" }}
            />
            <stop
              offset="0.5069"
              style={{ stopColor: "#464646", stopOpacity: "0.1401" }}
            />
            <stop
              offset="0.6775"
              style={{ stopColor: "#343434", stopOpacity: "0.1262" }}
            />
            <stop
              offset="0.8763"
              style={{ stopColor: "#161616", stopOpacity: "0.1101" }}
            />
            <stop
              offset="1"
              style={{ stopColor: "#000000", stopOpacity: "0.1" }}
            />
          </linearGradient>
          <path
            style={{ fill: "url(#SVGID_1_)" }}
            d="M1296.2,140.4c-35.5-26-85.8-18.3-111.8,17.1L726.1,781.4c-26.3,34.3-75.8,41.5-110.8,15.8L343,597.2
		l66.6,284.3l165.7,121.7c35.5,26,85.8,18.3,111.8-17.1l458.2-623.9c26.3-34.3,75.8-41.5,110.8-15.8L1543,557.1l-80.1-294.2
		L1296.2,140.4z"
          />
        </g>
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="1844.2676"
          y1="687.6079"
          x2="2000.4714"
          y2="687.6079"
        >
          <stop
            offset="0"
            style={{ stopColor: "#FFFFFF", stopOpacity: "5.000000e-02" }}
          />
          <stop
            offset="7.196940e-02"
            style={{ stopColor: "#F8F8F8", stopOpacity: "6.140047e-02" }}
          />
          <stop
            offset="0.1731"
            style={{ stopColor: "#E6E6E6", stopOpacity: "7.742382e-02" }}
          />
          <stop
            offset="0.2916"
            style={{ stopColor: "#C8C8C8", stopOpacity: "9.618902e-02" }}
          />
          <stop
            offset="0.4232"
            style={{ stopColor: "#9E9E9E", stopOpacity: "0.117" }}
          />
          <stop
            offset="0.5639"
            style={{ stopColor: "#696969", stopOpacity: "0.1393" }}
          />
          <stop
            offset="0.6313"
            style={{ stopColor: "#4D4D4D", stopOpacity: "0.15" }}
          />
          <stop
            offset="0.7041"
            style={{ stopColor: "#464646", stopOpacity: "0.1362" }}
          />
          <stop
            offset="0.8065"
            style={{ stopColor: "#343434", stopOpacity: "0.1167" }}
          />
          <stop
            offset="0.9258"
            style={{ stopColor: "#161616", stopOpacity: "9.409241e-02" }}
          />
          <stop
            offset="1"
            style={{ stopColor: "#000000", stopOpacity: "8.000000e-02" }}
          />
        </linearGradient>
        <path
          style={{ fill: "url(#SVGID_2_)" }}
          d="M1921.5,832.2l63.5-86.5c26-35.5,18.3-85.8-17.1-111.8L1844.3,543L1921.5,832.2z"
        />
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1="-129"
          y1="482.215"
          x2="101.1243"
          y2="482.215"
        >
          <stop
            offset="0"
            style={{ stopColor: "#FFFFFF", stopOpacity: "5.000000e-02" }}
          />
          <stop
            offset="9.001449e-02"
            style={{ stopColor: "#F8F8F8", stopOpacity: "5.450073e-02" }}
          />
          <stop
            offset="0.2167"
            style={{ stopColor: "#E6E6E6", stopOpacity: "6.083310e-02" }}
          />
          <stop
            offset="0.365"
            style={{ stopColor: "#C8C8C8", stopOpacity: "6.824888e-02" }}
          />
          <stop
            offset="0.5299"
            style={{ stopColor: "#9E9E9E", stopOpacity: "7.649325e-02" }}
          />
          <stop
            offset="0.7083"
            style={{ stopColor: "#686868", stopOpacity: "8.541629e-02" }}
          />
          <stop
            offset="0.8952"
            style={{ stopColor: "#282828", stopOpacity: "9.476205e-02" }}
          />
          <stop
            offset="1"
            style={{ stopColor: "#000000", stopOpacity: "0.1" }}
          />
        </linearGradient>
        <path
          style={{ fill: "url(#SVGID_3_)" }}
          d="M39,373.9l-87.6-64.4l-64.9,88.4c-26,35.5-18.3,85.8,17.1,111.8l197.6,145.1L39,373.9z"
        />
      </svg>
    </div>
  );
};

export default BackgroundImage;
