import React from "react";
import { Col, Row } from "reactstrap";
import Souscripteur from "./Souscripteur/Souscripteur";
import classes from "./UserInformation.module.css";
import FamilyComposition from "./FamilyComposition/FamilyComposition";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import ParcoursContext from "../../Context/ParcoursContext";
import moment from "moment";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";

function UserInformation() {
  const navigate = useNavigate();
  const [openf, setOpenf] = React.useState(false);
  const { souscripteur, productColor, confirmButtonColor, retourButtonColor } =
    React.useContext(ParcoursContext);
  const [errorMessage, setErrorMessage] = React.useState(
    "Vous devez remplir tous les champs avec *"
  );
  function isValidNom(name) {
    var regex = new RegExp(/^[a-zA-Z]{1,30}[' ']{0,1}[a-zA-Z]{1,30}$/);
    let validNom = regex.test(name);
    
    return validNom;
  }
  
  const nextHandler = () => {
    let finaltest = souscripteur.every((val, index) => {
      let test = Object.keys(val).every((key) => {
      
        if (val[key] === "") {
          setErrorMessage("Vous devez remplir tous les champs avec *");
          return false;
        } else if (key === "mail") {
          var regex = new RegExp(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/
          );
          let validEmail = regex.test(val[key]);
          setErrorMessage("Veuillez Verifier votre email !");
          return validEmail;
        }   else if(key==="nom"){
          if (isValidNom(val[key])===false){
            setErrorMessage("Veuillez Verifier votre nom !");
          }
          return isValidNom(val[key])
        }
        else if(key==="prenom"){
          if (isValidNom(val[key])===false){
            setErrorMessage("Veuillez Verifier votre prénom !");
          }
          return isValidNom(val[key])
        }
        else if (
          key === "dn" &&
          parseInt(moment(val[key], "YYYY-MM-DD").format("YYYY")) < 1937
        ) {
          setErrorMessage("La date de naissance doit être supérieure à 1937");
          return false;
        }
        return true;
      });
      return test;
    });
    finaltest ? navigate("/Proposition") : setOpenf(true);
  };

  return (
    <>
      <BackgroundImage color={productColor} />
      <section className={classes.container}>
        
        <Row className={classes.titleRow}>
          <Col className={classes.titleCol}>
            <h3 className={classes.title} style={{ color: productColor }}>
              Parcours IJH
            </h3>
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Souscripteur />
          <FamilyComposition />
        </Row>
        
        <Row style={{ marginTop: "2rem" }}>
          <Col className={classes.RetourCol}>
            <Button
              className={classes.retourButton}
              style={{
                backgroundColor: retourButtonColor,
              }}
              disabled={true}
            >
              {" "}
              <FontAwesomeIcon
                style={{ marginRight: "0.5rem" }}
                icon={faArrowLeft}
              />
              Retour
            </Button>
          </Col>
          <Col>
            <Button
              className={classes.confirmButton}
              style={{
                backgroundColor: confirmButtonColor
                  ? confirmButtonColor
                  : "#254484",
              }}
              onClick={nextHandler}
            >
              Confirmer{" "}
              <FontAwesomeIcon
                style={{
                  marginLeft: "0.5rem",
                }}
                icon={faArrowRight}
              />
            </Button>
          </Col>
        </Row>
      </section>
      <Snackbar
        open={openf}
        autoHideDuration={3000}
        onClose={() => setOpenf(false)}
      >
        <Alert
          onClose={() => setOpenf(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default UserInformation;
