import React from "react";
import { Row, Col, Form, Button, Label, Input } from "reactstrap";
import { Alert, Snackbar, Backdrop } from "@mui/material";
import classes from "./InformationsBancaires.module.css";
import IBAN from "iban";
import ParcoursContext from "../../Context/ParcoursContext";
import bicValidator from "bic-validator";
import axios from "axios";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import InputComponent from "../../components/Input/InputComponent";
import Swal from "sweetalert2";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
function InformationsBancaires() {
  const {
    souscripteur,
    setSouscripteur,
    customerInformations,
    setCustomerInformations,

    productColor,
    retourButtonColor,
    confirmButtonColor,
    entreprise,
    organisme,
  } = React.useContext(ParcoursContext);
  const navigate = useNavigate();
  const params = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [proposition, setProposition] = React.useState(
    params?.state?.proposition
  );
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = React.useState(params?.state?.data);

  const [openBic, setOpenBic] = React.useState(false);
  const [openIban, setOpenIban] = React.useState(false);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [progress, setProgress] = React.useState(0);

  const rows = [
    [
      {
        label: "Adresse",
        value: souscripteur[0]?.adresse,
        disabled: true,
        name: "adresse",
        type: "text",
      },
    ],
    [
      {
        label: "Ville",
        value: souscripteur[0]?.ville,
        disabled: true,
        name: "ville",
        type: "text",
      },
      {
        label: "Code postal",
        value: souscripteur[0]?.cp,
        disabled: true,
        name: "cp",
        type: "text",
      },
    ],
    [
      {
        label: "BIC prélèvement",
        value: souscripteur[0]?.bic,
        disabled: false,
        name: "bic",
        type: "text",
        required: true,
      },
      {
        label: "IBAN prélèvement",
        value: souscripteur[0]?.iban,
        disabled: false,
        name: "iban",
        type: "text",
        required: true,
      },
    ],
    [
      {
        label: "BIC remboursement",
        value: souscripteur[0]?.bicremboursement,
        disabled: false,
        name: "bicremboursement",
        type: "text",
      },
      {
        label: "IBAN remboursement",
        value: souscripteur[0]?.ibanremboursement,
        disabled: false,
        name: "ibanremboursement",
        type: "text",
      },
    ],
    [
      {
        label: "Jour de prélèvement",
        value: souscripteur[0]?.jourprelevement,
        disabled: false,
        name: "jourprelevement",
        type: "select",
      },
      {
        label: "Nom de la banque prélèvement",
        value: souscripteur[0]?.banquePrelevement,
        disabled: false,
        name: "banquePrelevement",
        type: "text",
      },
    ],
    [
      {
        label: "Nom de la banque remboursement",
        value: souscripteur[0]?.banqueRemboursement,
        disabled: false,
        name: "banqueRemboursement",
        type: "text",
      },
    ],
  ];

  const [nomDebiteur, setNomDebiteur] = React.useState(
    souscripteur[0]?.nom + " " + souscripteur[0]?.prenom
  );

  // eslint-disable-next-line no-unused-vars
  let childsNbr = 0;
  // eslint-disable-next-line no-unused-vars
  let conjointCount = 0;
  function isValidIBan() {
    return IBAN.isValid(souscripteur[0]?.iban);
  }

  function isValidBic() {
    return bicValidator.isValid(souscripteur[0]?.bic);
  }

  React.useEffect(() => {
    let id_pros = JSON.parse(
      sessionStorage.getItem("_subs_tags")
    )?.id_prospect_md5_with_givenTgas;
    const id = new URLSearchParams(window.location.search).get("IdAdhesion");
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/get_object_from_session_url/${id}`
        )
        .then((res) => {
          if (res.data?.error === true) {
            Swal.fire({
              icon: "error",
              title: "Erreur",
              text: res.data?.message.message,
            });
            setOpenbackdrop(false);
          }
          setProposition(res.data?.proposition);
          setCustomerInformations(res.data?.customerInfo);
          setSouscripteur(res.data?.souscripteur);
          sessionStorage.setItem("SessionURLID", res.data?.id_opp);
          sessionStorage.setItem(
            "_subs_tags",
            JSON.stringify(res.data?._subs_tags)
          );
          navigate("/fiche", {
            state: {
              proposition: res.data?.proposition,
            },
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: err,
          });
          setOpenbackdrop(false);
        });
    } else if (params.state) {
      let newCustomerInformations = [];
      customerInformations.forEach((element, index) => {
        var email = "";
        if (element?.relationship_rank === 1) {
          email = souscripteur[0]?.mail;
        } else if (element?.relationship_rank === 2) {
          email = "conjoint" + souscripteur[0]?.mail;
        } else {
          email =
            "Enfant" +
            (element?.relationship_rank - 10) +
            souscripteur[0]?.mail;
        }
        newCustomerInformations.push({
          number: element?.relationship_rank,
          primary_subscriber: element?.primary_subscriber,
          insured: true,
          beneficiary: false,
          birth_info: {
            date: element?.birth_info?.date,
          },
          address: {
            street_name:
              souscripteur[0]?.adresse +
              ", " +
              souscripteur[0].cp +
              ", " +
              souscripteur[0].ville,
            zip_code: souscripteur[0]?.cp,
            city: souscripteur[0]?.ville,
            code_insee: souscripteur[0]?.code_insee,
          },
          id_pros:
            element.relationship_rank === 1
              ? Object.keys(id_pros).find((key) => id_pros[key] === "Prospect")
              : element.relationship_rank === 2
              ? Object.keys(id_pros).find((key) => id_pros[key] === "Conjoint")
              : Object.keys(id_pros).find(
                  (key) =>
                    id_pros[key] ===
                    "Enfant" + (element?.relationship_rank - 10)
                ),
          job: {
            csp_insee: "Toutes",
            regime: "Régime général",
            number_yes: 0,
            force_yes: false,
          },
          contract_information: {
            number: 476820,
            product: [process.env.REACT_APP_IJH],
          },
          relationship: element?.relationship,
          relationship_rank: element?.relationship_rank,
          mail: email,
          identity: {
            civility: souscripteur[index]?.civility,
            use_name: souscripteur[index]?.nom,
            first_name: souscripteur[index]?.prenom,
          },
          phone: [
            {
              type: "Mobile",
              country_prefix: souscripteur[0]?.country_prefix,
              number: souscripteur[0]?.phone,
            },
          ],
        });
      });
      setCustomerInformations(newCustomerInformations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  souscripteur &&
    souscripteur.map((CI) => {
      if (CI.relationship === "children") {
        childsNbr++;
      } else if (CI.relationship === "married") {
        conjointCount++;
      }
      return null;
    });

  const signContract = async (
    url,
    id_affaire,
    num_contrat,
    file_url_bulletin,
    file_url_certif
  ) => {
    var files = [];
    let listProspects = [];
    if (souscripteur.length > 1) {
      souscripteur
        .filter((element) => element.relationship_rank >= 2)
        ?.forEach((element) => {
          listProspects = [
            ...listProspects,
            {
              name: element?.nom,
              surname: element?.prenom,
              DN: element?.dn,
              id: "",
              id_opp: "",
              campagne_id: "",
              post: "",
              interlocuteur: "",
              ville: "",
              situation: element?.relationship === "married" ? "married" : "",
              users_nom: "",
              users_prenom: "",
              adresse_mail: "",
              civilite: element?.civility,
              prospects_civ: "",

              prospects_situation: "",
              nb_enfants: "",
              adresses_adresse: "",
              streetNumber: "",
              CP: "",
              streetName: "",
              adresses_ville: "",
              complement_adr: "",
              tel: "",
              tel2: "",
              mobile: "",
              first_campagne: "",
              description: "",
              etat: "",
              lat: "",
              latitude: null,
              longitude: null,
              commercieux_id: "",
              commentaire: "",
              geom: "",
              tags: [],
              lng: "",
              date_naiss: "",
              collab: "",
              id_pros: "",
              id_camp: "",
              coment_plus: "",
              code: "",
              rue: "",
              numero: "",
              complement: "",
              mail: "",
              nb_enfant: "",
              infcomp: "",
              relation:
                element.relationship === "married" ? "Conjoint" : "Descendant",
              pays: "",
              invalidForms: [],
              deleted: false,
              information_bancaire: {
                id: "",
                titulaire_compte: "",
                iban: "",
                bic_swift: "",
                type_iban: "",
                nom_banque: "",
              },
              prospects_id: "",
            },
          ];
        });
    }

    files = [
      ...files,
      {
        url: url,
        page: parseInt(process.env.REACT_APP_SIGNATURE_PAGE),
        position: process.env.REACT_APP_SIGNATURE_POSITION_CONTRAT,
        file_name: "contract",
      },
    ];
    let formData = {
      id_opp: sessionStorage.getItem("SessionURLID"),
      num_contrat: num_contrat.replace("AE/FS", "RELP1222"),
      id_affaire: id_affaire,
      files: files,
      data: {
        info_specifiques_campagnes: {
          budget: null,
          ex43: null,
          ex44: null,
          ex47: null,
          ex31: null,
          ex32: null,
          ex11: null,
          ex12: null,
          ex16: null,
          ex19: null,
          pb: null,
          pbo: null,
          pbob: null,
          pnr: null,
        },
        info_specifiques_ligne_produit: {
          Ro: null,
          Ro_conjoint: null,
          annee_naissance: null,
          annee_naissance_conjoint: null,
          annee_naissance_enfant1: null,
          annee_naissance_enfant2: null,
          annee_naissance_enfant3: null,
          annee_naissance_enfant4: null,
          annee_naissance_enfant5: null,
          annee_naissance_enfant6: null,
          ro_enfant1: null,
          ro_enfant2: null,
          ro_enfant3: null,
          ro_enfant4: null,
          ro_enfant6: null,
          situation_pro: "111",
        },
        prospects: {
          listProspects: listProspects,
          mainProspect: {
            interlocuteur: null,
            tags: [],
            invalidForms: [],
            id: null,
            prospects_id: null,
            post: null,
            ville: souscripteur[0]?.ville,
            situation: null,
            adresse_mail: souscripteur[0]?.mail,
            civilite: souscripteur[0]?.civility,
            name: souscripteur[0]?.prenom,
            surname: souscripteur[0]?.nom,
            DN: souscripteur[0]?.dn,
            nb_enfants:
              souscripteur[1]?.relationship === "married"
                ? souscripteur.length - 2
                : souscripteur.length - 1,
            streetNumber: null,
            longitude: null,
            latitude: null,
            CP: souscripteur[0]?.cp,
            streetName: null,
            complement_adr: null,
            tel: souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
            tel2: null,
            mobile: souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
            description: null,
            relation: "principal",
          },
        },
      },
    };

    const response = await fetch(file_url_bulletin);
    const blob = await response.blob();

    let f = new FormData();
    f.append("file[]", blob, "bulletin.pdf");
    f.append("id_pros", sessionStorage.getItem("id_prospect"));
    f.append("id_type_doc", 25);
    f.append("id_affaire", id_affaire);
    f.append("name_doc", "bulletin_");

    axios
      .post(
        `${process.env.REACT_APP_GEOPROD_APIURL}/upload_document_affaire?`,
        f,
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then(async (res) => {
        const response = await fetch(file_url_certif);
        const blob = await response.blob();

        let f = new FormData();
        f.append("file[]", blob, "certif.pdf");
        f.append("id_pros", sessionStorage.getItem("id_prospect"));
        f.append("id_type_doc", 30);
        f.append("id_affaire", id_affaire);
        f.append("name_doc", "certif");

        axios.post(
          `${process.env.REACT_APP_GEOPROD_APIURL}/upload_document_affaire?`,
          f,
          {
            headers: {
              idSession: localStorage.getItem("token"),
            },
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/yousign/e-yousign`,
        formData,
        {
          headers: {
            idSession: localStorage.getItem("api_key"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        if (res.data.error === true) {
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: res.data?.message.message,
          });
          setOpenbackdrop(false);
        } else {
          // window.open(res.data?.redirect_url, "_blank");
          navigate("/Proposition/InformationBancaires/Success");
          setOpenbackdrop(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: err,
        });
        setOpenbackdrop(false);
      });
  };
  const ContractFillHandler = async (
    num_contrat,
    file_url_bulletin,
    file_url_certif,

    id_affaire
  ) => {
    let newData = {};
    if (
      data[
        "Souhaitez-vous être garantie par une indemnité journalière en cas d’hospitalisation suite à maladie ou accident ?"
      ] === "oui"
    ) {
      newData[
        "Souhaitez-vous être garantie par une indemnité journalière en cas d’hospitalisation suite à maladie ou accident_oui"
      ] = "True";
    } else {
      newData[
        "Souhaitez-vous être garantie par une indemnité journalière en cas d’hospitalisation suite à maladie ou accident_non"
      ] = "True";
    }
    if (
      data[
        "Une indemnité de 40€ en cas d’hospitalisation qui est doublée en cas d’accident vous parait-elle suffisante ?"
      ] === "oui"
    ) {
      newData[
        "Une indemnité de 40€ en cas d’hospitalisation qui est doublée en cas d’accident vous parait-elle suffisante_oui"
      ] = "True";
    } else {
      newData[
        "Une indemnité de 40€ en cas d’hospitalisation qui est doublée en cas d’accident vous parait-elle suffisante_non"
      ] = "True";
    }
    if (data["Avez-vous déjà ce type d’indemnité ?"] === "oui") {
      newData["Avez-vous déjà ce type d’indemnité_oui"] = "True";
    } else {
      newData["Avez-vous déjà ce type d’indemnité_non"] = "True";
    }
    newData["si oui quelle montant"] = data["quel monatnt?"];
    newData["quel assureur"] = data["quel assureur?"];
    if (
      data[
        "Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation ?"
      ] === "oui"
    ) {
      newData[
        "Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions_oui"
      ] = "True";
    } else {
      newData[
        "Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions_non"
      ] = "True";
    }
    if (
      data[
        "Vous ou l’un des assurés au contrat a-t’il une hospitalisation programmée ?"
      ] === "oui"
    ) {
      newData["Avez-vous une hospitalisation programmée_oui"] = "True";
    } else {
      newData["Avez-vous une hospitalisation programmée_non"] = "True";
    }
    if (data["Vous ou l'un des assurés pratique-t-il un sport ?"] === "oui") {
      newData["Pratiquez-vous un sport_oui"] = "True";
    } else {
      newData["Pratiquez-vous un sport_non"] = "True";
    }
    newData["par telephone_oui"] = "True";
    newData["par mail_oui"] = "True";
    newData["par courtier_oui"] = "True";
    newData["nomassure_text"] =
      souscripteur[0].nom + " " + souscripteur[0].prenom;
    newData["date_signature"] = moment(Date.now()).format("DD/MM/yyyy");
    newData["villeassure_text"] = souscripteur[0]?.ville;
    newData["nomassure"] = souscripteur[0].nom;
    newData["prenomassure_text"] = souscripteur[0].prenom;
    newData["cpassure_text"] = souscripteur[0].cp;
    newData["mailassure_text"] = souscripteur[0].mail;
    newData["mobileassure_text"] =
      souscripteur[0].country_prefix + " " + souscripteur[0].phone;
    newData["date_naissanceassure_text"] = moment(souscripteur[0].dn).format(
      "DD-MM-YYYY"
    );

    newData["personne_politiquement_exposee_non"] = "True";
    newData["Nbre d’enfants à charge"] = childsNbr;
    newData["signature_electronique"] = "True";
    newData["nom_courtier"] = entreprise?.nom;
    newData["rcs_courtier"] = entreprise?.rcs ? entreprise?.rcs : "";
    newData["numero_orias_courtier"] = entreprise?.orias
      ? entreprise?.orias
      : "";
    newData["adresse_courtier"] = entreprise?.adresse;
    newData["civility_nom_prenom_conseiller"] =
      organisme?.user?.civilite +
      "   " +
      organisme?.user?.nom +
      "   " +
      organisme?.user?.prenom;
    newData["numero_orias_conseiller"] =
      organisme?.user?.orias_producteur !== null
        ? organisme?.user?.orias_producteur
        : "";
    newData["nom_conseiller"] =
      organisme?.user?.nom + "   " + organisme?.user?.prenom;
    newData["contrat"] = "RELAXEO";
    newData["salarié"] = "True";
    if (
      data["Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?"] ===
      "oui"
    ) {
      newData[
        "Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle_oui"
      ] = "True";
    } else {
      newData[
        "Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle_non"
      ] = "True";
    }
    if (
      data[
        "Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?"
      ] === "oui"
    ) {
      newData[
        "Vous déclarez gérer vous-même vos papiers sans une aide extérieure_oui"
      ] = "True";
    } else {
      newData[
        "Vous déclarez gérer vous-même vos papiers sans une aide extérieure_non"
      ] = "True";
    }
    if (
      data[
        "Vous avez déclaré ne pas pratiquer de discipline sportive exclue aux conditions générales du contrat"
      ] === "oui"
    ) {
      newData[
        "Vous avez déclaré ne pas pratiquer de discipline sportive exclue aux conditions générales du contrat_oui"
      ] = "True";
    } else {
      newData[
        "Vous avez déclaré ne pas pratiquer de discipline sportive exclue aux conditions générales du contrat_non"
      ] = "True";
    }

    const A = true;
    const C = conjointCount === 1;
    const NC = conjointCount === 0;
    const E0 = childsNbr === 0;
    const E1 = childsNbr === 1;
    const E2 = childsNbr === 2;
    const E3 = childsNbr === 3;
    let deletePagesFIC;
    if (A && NC && E0) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6, 7];
    } else if (A && C && E0) {
      deletePagesFIC = [2, 3, 4, 5, 6, 7];
    } else if (A && C && E1) {
      deletePagesFIC = [1, 3, 4, 5, 6, 7];
    } else if (A && C && E2) {
      deletePagesFIC = [1, 2, 4, 5, 6, 7];
    } else if (A && C && E3) {
      deletePagesFIC = [1, 2, 3, 5, 6, 7];
    } else if (A && NC && E1) {
      deletePagesFIC = [1, 2, 3, 4, 6, 7];
    } else if (A && NC && E2) {
      deletePagesFIC = [1, 2, 3, 4, 5, 7];
    } else if (A && NC && E3) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6];
    }
    let fileName = file_url_bulletin.split("/");
    axios
      .post(
        process.env.REACT_APP_EDITIQUE + "/editique_pdf/",

        {
          idModel: process.env.REACT_APP_FICHE_CONSEIL_MODEL,
          deletePages: deletePagesFIC,
          data: newData,
          insertPdfs: [fileName[fileName.length - 1]],
          option: "True",
        },
        {
          headers: {
            apiKey: process.env.REACT_APP_EDITIQUE_APIKEY,
          },
        }
      )
      .then((res) => {
        signContract(
          res.data?.file_url,
          id_affaire,
          num_contrat,
          file_url_bulletin,
          file_url_certif
        );
      });
  };

  const subscribeHandler = async (signature_electronique) => {
    setOpenbackdrop(true);
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/subscription/subscribe_v2`,
        {
          call_source: "parcours",
          document_produit: "ijh_blessure",
          option: [
            process.env.REACT_APP_ASSISTANCE_CONTRACT,
            process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT,
          ],
          id_opp: sessionStorage.getItem("SessionURLID"),
          ligne_produit: "36",
          fileType: "url",
          information_bancaire: {
            bic_swift: souscripteur[0]?.bicremboursement
              ? souscripteur[0]?.bicremboursement
              : souscripteur[0]?.bic,
            iban: souscripteur[0]?.ibanremboursement
              ? souscripteur[0]?.ibanremboursement
              : souscripteur[0]?.iban,
            type_iban: "prestation",
            titulaire_compte: `${souscripteur[0]?.nom} ${souscripteur[0]?.prenom}`,
            nom_banque: souscripteur[0]?.banqueRemboursement
              ? souscripteur[0]?.banqueRemboursement
              : souscripteur[0]?.banquePrelevement,
          },

          pricing: {
            third_app: {
              name: "ASolutions",
              id_external: 476820,
            },

            customer_information: customerInformations,
            contract_information: {
              effective_date: moment().add(34, "days").format("DD/MM/yyyy"),
              mode_splitting: "Mensuel",
              signature_date: moment().format("DD/MM/yyyy"),

              payment_information: {
                mode_splitting: "Mensuel",
                withdrawal_day: souscripteur[0]?.jourprelevement,
                payment_mode: "Direct Debit",
                bic: souscripteur[0]?.bic,
                iban: souscripteur[0]?.iban,
                nom_banque: souscripteur[0]?.banquePrelevement,
                nom_prenom_debiteur: nomDebiteur,
              },
            },
          },
        },
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((result) => {
        if (result.data?.error === true) {
          if (
            result.data?.message?.message?.contract_information
              ?.payment_information["476820"]?.iban
          ) {
            Swal.fire({
              icon: "error",
              text: result.data.message.message.contract_information
                .payment_information["476820"].iban,
              showConfirmButton: true,
              // timer: 3000,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Erreur",
              text: result.data?.message.message,
            });
          }
          return setOpenbackdrop(false);
        }
        ContractFillHandler(
          result.data?.num_contrat,
          result.data?.pdf,
          result.data?.pdf_certif,
          result.data?.id_affaire
        );
      })

      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: err,
        });
        setOpenbackdrop(false);
        console.log(err);
      });
  };

  function valide() {
    if (isValidBic() === false) {
      setOpenBic(true);
    } else if (isValidIBan(souscripteur[0]?.iban) === false) {
      setOpenIban(true);
    } else {
      subscribeHandler();
    }
  }

  const handleCloseBic = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenBic(false);
  };
  const handleCloseIban = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenIban(false);
  };

  return (
    <>
      <BackgroundImage color={productColor} />
      <div className={classes.container}>
        <Row className={classes.titleRow}>
          <Col className={classes.titleCol}>
            <h3 className={classes.title} style={{ color: productColor }}>
              Informations Bancaires
            </h3>
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form className={classes.form}>
            <Row>
              {rows.map((col, index) => {
                return col.map((val, idx) => {
                  return (
                    <InputComponent
                      key={idx}
                      label={val.label || ""}
                      value={val.value || ""}
                      disabled={val.disabled || ""}
                      name={val.name || ""}
                      type={val.type || ""}
                      required={val.required || ""}
                    />
                  );
                });
              })}{" "}
            </Row>
            <Row>
              <Col xs="12" lg="6" style={{ margin: "0.4rem 0" }}>
                <Label for="nomDEb" className={classes.inputTitle}>
                  Nom et Prénom du débiteur
                </Label>
                <Input
                  id="nomDeb"
                  name="nom"
                  value={nomDebiteur}
                  onChange={(e) => {
                    setNomDebiteur(e.target.value);

                    let tab = [...souscripteur];
                    tab[0].nom_prenom_debiteur = nomDebiteur;
                    setSouscripteur(tab);
                  }}
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col className={classes.RetourCol}>
              <Button
                style={{
                  backgroundColor: retourButtonColor,
                }}
                className={classes.retourButton}
                onClick={() => navigate("/fiche")}
              >
                {" "}
                <FontAwesomeIcon
                  style={{ marginRight: "0.5rem" }}
                  icon={faArrowLeft}
                />
                Retour
              </Button>
            </Col>
            <Col>
              <Button
                className={classes.confirmButton}
                style={{
                  backgroundColor: confirmButtonColor,
                }}
                onClick={valide}
              >
                Confirmer{" "}
                <FontAwesomeIcon
                  style={{ marginLeft: "0.5rem" }}
                  icon={faArrowRight}
                />
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
      <Snackbar open={openBic} autoHideDuration={3000} onClose={handleCloseBic}>
        <Alert onClose={handleCloseBic} severity="error" sx={{ width: "100%" }}>
          Veuillez vérifier votre bic
        </Alert>
      </Snackbar>
      <Snackbar
        open={openIban}
        autoHideDuration={3000}
        onClose={handleCloseIban}
      >
        <Alert
          onClose={handleCloseIban}
          severity="error"
          sx={{ width: "100%" }}
        >
          Veuillez vérifier votre iban
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openbackdrop}
      >
        <div className={classes.loaderContainer}>
          <Box sx={{ width: "100%" }}>
            <CircularProgress style={{ width: "4rem", height: "4rem" }} />
          </Box>
          <h2>Veuillez patienter un instant.</h2>
        </div>
      </Backdrop>
    </>
  );
}

export default InformationsBancaires;
