import React from 'react';
import Container from '@material-ui/core/Container';

import Button from '@material-ui/core/Button';

import { useNavigate } from 'react-router-dom';
import ParcoursContext from '../../Context/ParcoursContext';
import moment from 'moment/moment';
import axios from 'axios';
import classes from './Proposition.module.css';
import SendEmail from './SendEmail/SendEmail';
import SessionStorageService from '../../utils/sessionStorageService';
import { Col, Row } from 'reactstrap';
import { Backdrop } from '@mui/material';
import pdfIcon from '../../assets/images/pdf.png';
import mailIcon from '../../assets/images/mail.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import Switch from '@material-ui/core/Switch';

export default function Proposition(props) {
  const [modal, setModal] = React.useState(false);
  // eslint-disable-next-line
  const [openf, setOpenf] = React.useState(false);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  
  const [idUrl, setIdUrl] = React.useState(sessionStorage.getItem('idUrl'));
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();

  const {
    souscripteur,
    setCustomerInformations,
    customerInformations,
    proposition,
    productColor,
    retourButtonColor,
    confirmButtonColor,
    entreprise,
  } = React.useContext(ParcoursContext);

  const [pdf, setPdf] = React.useState();
  React.useEffect(() => {
    
    let customerInformations = [];
    souscripteur.forEach((val) => {
      customerInformations.push({
        number: 476819 + val?.relationship_rank,
        primary_subscriber: val.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: moment(val.dn, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        },
        address: {
          zip_code: souscripteur[0].cp,
          code_insee: souscripteur[0].code_insee,
        },
        job: {
          csp_insee: 'Toutes',
          regime: 'Régime général',
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
        },
        relationship: val?.relationship,
        relationship_rank: val?.relationship_rank,
      });
    });
    let productsIds = {};

    for (let i = 0; i < customerInformations.length; i++) {
      productsIds[0] = Array(customerInformations.length).fill([
        parseInt(process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT),
      ]);
      productsIds[1] = Array(customerInformations.length).fill([
        parseInt(process.env.REACT_APP_ASSISTANCE_CONTRACT),
      ]);
      productsIds[2] = Array(customerInformations.length).fill([
        parseInt(process.env.REACT_APP_IJH),
      ]);
    }
    setCustomerInformations(customerInformations);

    var listProspects = [];

    souscripteur
      .filter((element) => element.relationship_rank >= 2)
      .forEach((element) => {
        listProspects = [
          ...listProspects,
          {
            name: element.nom,
            surname: element.prenom,
            DN: element?.dn,
            id: '',
            id_opp: '',
            campagne_id: '',
            post: '',
            interlocuteur: '',
            ville: '',
            situation: element.relationship === 'married' ? 'married' : '',
            subs_tag:
              element.relationship_rank === 2
                ? 'Conjoint'
                : 'Enfant' + (element.relationship_rank - 10),
            users_nom: '',
            users_prenom: '',
            adresse_mail: '',
            civilite: element.civility,
            prospects_civ: '',

            prospects_situation: '',
            nb_enfants: '',
            adresses_adresse: '',
            streetNumber: '',
            CP: '',
            streetName: '',
            adresses_ville: '',
            complement_adr: '',
            tel: '',
            tel2: '',
            mobile: '',
            first_campagne: '',
            description: '',
            etat: '',
            lat: '',
            latitude: null,
            longitude: null,
            commercieux_id: '',
            commentaire: '',
            geom: '',
            tags: [],
            lng: '',
            date_naiss: '',
            collab: '',
            id_pros: '',
            id_camp: '',
            coment_plus: '',
            code: '',
            rue: '',
            numero: '',
            complement: '',
            mail: '',
            nb_enfant: '',
            infcomp: '',
            relation:
              element.relationship === 'married' ? 'Conjoint' : 'Descendant',
            pays: '',
            invalidForms: [],
            deleted: false,
            information_bancaire: {
              id: '',
              titulaire_compte: '',
              iban: '',
              bic_swift: '',
              type_iban: '',
              nom_banque: '',
            },
            prospects_id: '',
          },
        ];
      });

    let offre = {
      info_specifiques_campagnes: {},
      info_specifiques_ligne_produit: {},
      prospects: {
        listProspects: listProspects,
        mainProspect: {
          interlocuteur: null,
          tags: [],
          invalidForms: [],
          id: null,
          prospects_id: null,
          post: null,
          ville: souscripteur[0]?.ville,
          situation: null,
          adresse_mail: souscripteur[0]?.mail,
          civilite: souscripteur[0]?.civility,
          name: souscripteur[0]?.prenom,
          surname: souscripteur[0]?.nom,
          DN: souscripteur[0]?.dn,
          nb_enfants:
            souscripteur[1]?.relationship === 'married'
              ? souscripteur.length - 2
              : souscripteur.length - 1,
          streetNumber: null,
          longitude: null,
          latitude: null,
          CP: souscripteur[0].cp,
          streetName: null,
          complement_adr: null,
          tel: souscripteur[0]?.phone,
          tel2: null,
          mobile: null,
          description: null,
          relation: 'principal',
          subs_tag: 'Prospect',
        },
      },
    };
    axios
      .post(`${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/offre/add`, offre, {
        headers: {
          idSession: localStorage.getItem('token'),
        },
      })
      .then((res) => {
        sessionStorage.setItem('SessionURLID', res.data.id_opportunite_md5);
        sessionStorage.setItem('id_prospect', res.data.id_prospect_md5);
        sessionStorage.setItem(
          '_subs_tags',
          JSON.stringify(res.data._subs_tags)
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createHistory = () => {
    let id_pros = JSON.parse(
      sessionStorage.getItem('_subs_tags')
    ).id_prospect_md5_with_givenTgas;
    let newCustomerInformations = [];
    customerInformations.forEach((element, index) => {
      newCustomerInformations.push({
        number: element?.relationship_rank,
        primary_subscriber: element?.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: element.birth_info.date,
        },
        address: {
          street_name:
            souscripteur[0]?.adresse +
            ', ' +
            souscripteur[0].cp +
            ', ' +
            souscripteur[0].ville,
          zip_code: souscripteur[0]?.cp,
          city: souscripteur[0]?.ville,
          code_insee: souscripteur[0]?.code_insee,
        },
        id_pros:
          element.relationship_rank === 1
            ? Object.keys(id_pros).find((key) => id_pros[key] === 'Prospect')
            : element.relationship_rank === 2
            ? Object.keys(id_pros).find((key) => id_pros[key] === 'Conjoint')
            : Object.keys(id_pros).find(
                (key) =>
                  id_pros[key] === 'Enfant' + (element?.relationship_rank - 10)
              ),
        job: {
          csp_insee: 'Toutes',
          regime: 'Régime général',
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
          product: [parseInt(process.env.REACT_APP_IJH)],
        },
        relationship: element?.relationship,
        relationship_rank: element?.relationship_rank,
        mail: souscripteur[0]?.mail,
        identity: {
          civility: souscripteur[index].civility,
          use_name: souscripteur[index].nom,
          first_name: souscripteur[index].prenom,
        },
        phone: [
          {
            type: 'Mobile',
            country_prefix: souscripteur[0].country_prefix,
            number: souscripteur[0]?.phone,
          },
        ],
      });
    });

    let repriseAdhesion = sessionStorage.getItem('id_prospect') + Date.now();
    sessionStorage.setItem('repriseAdhesion', repriseAdhesion);
    axios.post(
      `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/set_object_from_session_url`,
      {
        id: repriseAdhesion,
        objet: {
          proposition: proposition,
          customerInfo: newCustomerInformations,
          souscripteur: souscripteur,
          id_opp: sessionStorage.getItem('SessionURLID'),
          _subs_tags: JSON.parse(sessionStorage.getItem('_subs_tags')),
        },
      }
    );
  };


  const calculPrice = () => {
    let price = 0;
    if (customerInformations.length === 1) {
      price = price + process.env.REACT_APP_INDIVIDUEL;
    }
    if (customerInformations.length > 1) {
      price = price + process.env.REACT_APP_FAMILLE;
    }
    return Number(price);
  };
  const calculPriceAnnuelle = () => {
    return (calculPrice() * 12 + 5.0).toFixed(2);
  };
 
  const uploadDevisOpportunite = async (file_url) => {
    const response = await fetch(file_url);
    const blob = await response.blob();

    let f = new FormData();
    f.append('file[]', blob, 'devis.pdf');
    f.append('id_pros', sessionStorage.getItem('id_prospect'));
    f.append('id_document', 117);
    f.append('id_opp', sessionStorage.getItem('SessionURLID'));
    f.append('name_doc', 'devis_');

    axios
      .post(
        `${process.env.REACT_APP_GEOPROD_APIURL}upload_document_opportunite`,
        f,
        {
          headers: {
            idSession: localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        setOpenbackdrop(false);
      })
      .catch((err) => {
        setOpenbackdrop(false);
        console.log(err);
      });
  };
  const sendEmail = async () => {
    const date_naissance = {};
    const civilite_nom_prenom = {};
    const lien_familial = {};

    souscripteur?.forEach((element) => {
      if (element.relationship === 'married') {
        lien_familial[`lien_familial_conjoint`] = `Conjoint`;
        date_naissance[`date_naissance_conjoint`] = element.dn;
        civilite_nom_prenom[`civilite_nom_prenom_conjoint`] =
          element.civility === 'Monsieur'
            ? 'M  ' + element.nom + ' ' + element.prenom
            : 'Mme ' + element.nom + ' ' + element.prenom;
      }
      if (element.relationship === 'children') {
        lien_familial[
          `lien_familial${element?.relationship_rank - 10}`
        ] = `Enfant${element?.relationship_rank - 10}`;
        date_naissance[`date_naissance${element?.relationship_rank - 10}`] =
          element.dn;
        civilite_nom_prenom[
          `civilite_nom_prenom${element?.relationship_rank - 10}`
        ] =
          element.civility === 'Monsieur'
            ? 'M  ' + element.nom + ' ' + element.prenom
            : 'Mme ' + element.nom + ' ' + element.prenom;
      }
    });
    if (proposition.length > 0) {
      setOpenbackdrop(true);
      axios
        .post(
          process.env.REACT_APP_EDITIQUE + '/editique_pdf/',
          {
            idModel: process.env.REACT_APP_DEVIS_MODEL,
            data: {
              dossier:"5.0",
              nom_produit:"RELAXEO",
              moyen_communication:"Vente à distance",
              garanties1:"- Indémnités Journalières Hospitalières : 40 euros / jour.",
              garanties2:"- Assistance",
              garanties3:"- Protection Juridique",
              conseil:entreprise?.nom ? entreprise?.nom+ ", " : " " ,
              adresse_org: entreprise?.adresse ? entreprise?.adresse : " " ,
              email_org:entreprise?.mail ? entreprise?.mail+ ", " : " ",
              tel_org:entreprise?.tel ? entreprise?.tel+ ", ": " ",
              nom_adherant: souscripteur[0].nom,
              prenom_adherant: souscripteur[0].prenom,
              date_effet_originale: moment(Date.now())
                .add(1, 'days')
                .format('DD-MM-YYYY'),
              date_naissance: moment(souscripteur[0].dn).format('DD-MM-YYYY'),
              // lieu_naissance: souscripteur[0].adresse,
             
              code_postal: souscripteur[0].cp,
              ville: souscripteur[0]?.ville,
              civi_nom_prenom_assure:
                souscripteur[0]?.civility === 'Monsieur'
                  ? 'M  ' +
                    souscripteur[0]?.nom +
                    ' ' +
                    souscripteur[0]?.prenom
                  : 'Mme  ' +
                    souscripteur[0]?.nom +
                    ' ' +
                    souscripteur[0]?.prenom,
              adresse:
                souscripteur[0].adresse +
                ', ' +
                souscripteur[0].cp +
                ', ' +
                souscripteur[0].ville,
              civilite: souscripteur[0]?.civility.toString(),

              cot_annuelle: calculPriceAnnuelle(),
              cot_mensuelle: calculPrice(),

              email: souscripteur[0].mail,
              num_devis: Date.now().toString(),
              personne_politiquement_exposee_non: 'True',
              lien_familial: 'Assuré',

              num_telephone:
                souscripteur[0].country_prefix + ' ' + souscripteur[0].phone,
              ...civilite_nom_prenom,
              ...date_naissance,
              ...lien_familial,
          
              
            },
          },
          {
            headers: {
              apiKey: process.env.REACT_APP_EDITIQUE_APIKEY,
            },
          }
        )
        .then((res) => {
          setPdf(res.data.file_url);
          uploadDevisOpportunite(res.data.file_url);
          setOpenbackdrop(false);
          const idSessionURL = Date.now() + SessionStorageService().getId();
          sessionStorage.setItem('idUrl', idSessionURL);
          setIdUrl(idSessionURL);
          if (modal === false) {
            createHistory();
            setModal(!modal);
          }
          setModal(!modal);
        });
    } else {
      setOpenf(true);
    }
  };

  const devisDownload = async () => {
    const date_naissance = {};
    const civilite_nom_prenom = {};
    const lien_familial = {};
   

    souscripteur?.forEach((element) => {
      if (element.relationship === 'married') {
        lien_familial[`lien_familial_conjoint`] = `Conjoint`;
        date_naissance[`date_naissance_conjoint`] = element.dn;
        civilite_nom_prenom[`civilite_nom_prenom_conjoint`] =
          element.civility === 'Monsieur'
            ? 'M  ' + element.nom + ' ' + element.prenom
            : 'Mme ' + element.nom + ' ' + element.prenom;
      }
      if (element.relationship === 'children') {
        lien_familial[
          `lien_familial${element?.relationship_rank - 10}`
        ] = `Enfant${element?.relationship_rank - 10}`;
        date_naissance[`date_naissance${element?.relationship_rank - 10}`] =
          element.dn;
        civilite_nom_prenom[
          `civilite_nom_prenom${element?.relationship_rank - 10}`
        ] =
          element.civility === 'Monsieur'
            ? 'M  ' + element.nom + ' ' + element.prenom
            : 'Mme ' + element.nom + ' ' + element.prenom;
      }
    });
    if (proposition.length > 0) {
      setOpenbackdrop(true);
      axios
        .post(
          process.env.REACT_APP_EDITIQUE + '/editique_pdf/',
          {
            idModel: process.env.REACT_APP_DEVIS_MODEL,
            data: {
              moyen_communication:"Vente à distance",
              nom_produit:"RELAXEO",
              dossier: "5.0",
              conseil:entreprise?.nom ? entreprise?.nom: " " ,
              adresse_org: entreprise?.adresse ? entreprise?.adresse : " " ,
              email_org:entreprise?.mail ? entreprise?.mail+ ", " : " ",
              tel_org:entreprise?.tel ? entreprise?.tel+ ", ": " ",
              garanties1:"- Indémnités Journalières Hospitalières : 40 euros / jour.",
              garanties2:"- Assistance",
              garanties3:"- Protection Juridique",
              civi_nom_prenom_assure:
                souscripteur[0]?.civility === 'Monsieur'
                  ? 'M  ' +
                    souscripteur[0]?.nom +
                    ' ' +
                    souscripteur[0]?.prenom
                  : 'Mme  ' +
                    souscripteur[0]?.nom +
                    ' ' +
                    souscripteur[0]?.prenom,
              date_effet_originale: moment(Date.now())
                .add(1, 'days')
                .format('DD-MM-YYYY'),
              nom_adherant: souscripteur[0].nom,
              prenom_adherant: souscripteur[0].prenom,
              date_naissance: moment(souscripteur[0].dn).format('DD-MM-YYYY'),
              // lieu_naissance: souscripteur[0].adresse,
              code_postal: souscripteur[0].cp,
              ville: souscripteur[0]?.ville,
              adresse:
                souscripteur[0].adresse +
                ', ' +
                souscripteur[0].cp +
                ', ' +
                souscripteur[0].ville,
              civilite: souscripteur[0]?.civility,
           
              cot_annuelle: calculPriceAnnuelle(),
              cot_mensuelle: calculPrice(),
              email: souscripteur[0].mail,
              
              lien_familial: 'Assuré',
              num_devis: Date.now().toString(),
              personne_politiquement_exposee_non: 'True',
              num_telephone:
                souscripteur[0].country_prefix + ' ' + souscripteur[0].phone,
              ...civilite_nom_prenom,
              ...date_naissance,
              ...lien_familial,
              
             
            },
          },
          {
            headers: {
              apiKey: process.env.REACT_APP_EDITIQUE_APIKEY,
            },
          }
        )
        .then((res) => {
          setPdf(res.data.file_url);
          window.open(res.data.file_url, '_blank');

          uploadDevisOpportunite(res.data.file_url);
        });
    } else {
      setOpenf(true);
    }
  };
  return (
    <>
      <BackgroundImage color={productColor} />
      <section className={classes.section}>
        <SendEmail
          SendEmail={sendEmail}
          modal={modal}
          idURL={idUrl}
          setModal={setModal}
          url={'/devis/'}
          devisDownload={devisDownload}
          devis={pdf}
        />

        <Container
          maxWidth='lg'
          style={{ padding: '2rem', alignItems: 'center' }}
        >
          <Row>
            <Col className={classes.propositionCol}>
              <p
                className={classes.propositionText}
                style={{ color: productColor }}
              >
                Proposition d'assurance
              </p>
            </Col>
          </Row>
          <Row>
            <Col className={classes.tarificationCol}>
              <p className={classes.tarificationText}>
                Nous vous avons déjà envoyé un e-mail contient le devis de
                tarification
              </p>
            </Col>
          </Row>
          <div className={classes.box}>
            <Row>
              <Col className={classes.switchComponent}>
                <p
                  className={
                    checked === true
                      ? classes.mensuelle
                      : classes.mensuelleChecked
                  }
                >
                  Mensuelle
                </p>
                <Switch
                  color='primary'
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <p
                  className={
                    checked === true
                      ? classes.annuelle
                      : classes.annuelleChecked
                  }
                >
                  Annuelle
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                {checked === false ? (
                  <p style={{ fontSize: '2rem', fontWeight: '600' }}>
                    Cotisation Mensuelle :{' '}
                    {calculPrice() > 0 ? calculPrice() : '0.00'} €
                  </p>
                ) : (
                  <p style={{ fontSize: '2rem', fontWeight: '600' }}>
                    Cotisation Annuelle :{' '}
                    {calculPriceAnnuelle() > 0 ? calculPriceAnnuelle() : '0.00'}{' '}
                    €
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col style={{ display: 'flex' }}>
                <Button className={classes.buttons} onClick={sendEmail}>
                  {' '}
                  <img
                    src={mailIcon}
                    alt='mail'
                    style={{ marginRight: '0.5rem' }}
                  />{' '}
                  Envoyer E-mail
                </Button>

                <Button className={classes.buttons} onClick={devisDownload}>
                  {' '}
                  <img
                    src={pdfIcon}
                    alt='pdf'
                    style={{ marginRight: '0.5rem' }}
                  />{' '}
                  Exporter PDF
                </Button>
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: '2rem' }}>
            <Col className={classes.RetourCol}>
              <Button
                className={classes.retourButton}
                style={{
                  backgroundColor: retourButtonColor,
                }}
                onClick={() => navigate('/')}
              >
                {' '}
                <FontAwesomeIcon
                  style={{ marginRight: '0.5rem' }}
                  icon={faArrowLeft}
                />
                Retour
              </Button>
            </Col>
            <Col>
              <Button
                className={classes.confirmButton}
                style={{
                  backgroundColor: confirmButtonColor,
                }}
                onClick={() => {
                  navigate('/fiche', {
                    state: {
                      proposition: proposition,
                      price: calculPrice(),
                    },
                  });
                }}
              >
                Confirmer{' '}
                <FontAwesomeIcon
                  style={{ marginLeft: '0.5rem' }}
                  icon={faArrowRight}
                />
              </Button>
            </Col>
          </Row>
          <Row>
            
          </Row>
        </Container>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openbackdrop}
          
        >
          <div className={classes.loaderContainer}>
            <Box sx={{ width: '100%' }}>
              <CircularProgress style={{ width: '4rem', height: '4rem' }} />
            </Box>
            <h2>Veuillez patienter un instant.</h2>
          </div>
        </Backdrop>
      </section>
    </>
  );
}
