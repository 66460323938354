import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Button, Input, Label } from "reactstrap";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import classes from "./FicheConseil.module.css";
import ParcoursContext from "../../Context/ParcoursContext";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const FicheConseil = () => {
  const params = useLocation();
  // const { proposition } = params?.state;
    // eslint-disable-next-line
  const [proposition, setProposition] = React.useState(params?.state?.proposition);
  const { retourButtonColor, confirmButtonColor, productColor, data , setData } =
    React.useContext(ParcoursContext);
  const navigate = useNavigate();
  const handleChange = (e) => {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const confirmedBtn = () => {
    if(data["Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?"] === "oui" )
    {
      Swal.fire({
               icon: "warning", 
              text: "Vous ne disposez pas des droits pour enregistrer ce contrat, Motif : 'curatelle' , veuillez contacter AS Solutions",
             confirmButtonColor: '#3085d6'
           });
    }
    if( data["êtes vous une personne politiquement exposée ?"] === "oui")
    {
      Swal.fire({
        icon: "warning", 
       text: "Vous ne disposez pas des droits pour enregistrer ce contrat, Motif : 'personne politiquement exposée' , veuillez contacter AS Solutions",
      confirmButtonColor: '#3085d6'
    });
    }
   if( data["êtes vous une personne politiquement exposée ?"] === "oui" && data["Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?"] === "oui")
    {
      Swal.fire({
        icon: "warning", 
       text: "Vous ne disposez pas des droits pour enregistrer ce contrat, Motifs : 'curatelle, personne politiquement exposée' , veuillez contacter AS Solutions",
      confirmButtonColor: '#3085d6'
    });
    }
    if(data["êtes vous une personne politiquement exposée ?"] === "non" && data["Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?"] === "non")
    navigate("/Proposition/InformationBancaires", {
      state: {
        proposition: proposition,
        data: data,
      },
    })
  }

  const sportsSansGaranties = (e) => {
    if (
      data[
        "Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
      ].includes(e.target.value)
    ) {
      let newData = data[
        "Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
      ].filter((elem) => elem !== e.target.value);
      setData({
        ...data,
        "Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :":
          newData,
      });
    } else
      setData({
        ...data,
        "Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :":
          [
            ...data[
              "Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
            ],
            e.target.value,
          ],
      });
  };
  
  
  return (
    <>
      <BackgroundImage />
      <Row className={classes.titleRow}>
        <Col className={classes.titleCol}>
          <h3 className={classes.title} style={{ color: productColor }}>
            Fiche Conseil
          </h3>
        </Col>
      </Row>
      <Form className={classes.form}>
        <Row>
          <Col xs="12" className={classes.title}>
            <h6>
              Souhaitez-vous être garantie par une indemnité journalière en cas
              d’hospitalisation suite à maladie ou accident ?
            </h6>
          </Col>
          <div style={{ display: "flex", marginLeft: "2rem" }}>
            <Col xs="12" className={classes.title}>
              <Label className={classes.btnradio}>
                <Input
                  value="non"
                  onClick={handleChange}
                  name="Souhaitez-vous être garantie par une indemnité journalière en cas d’hospitalisation suite à
            maladie ou accident ?"
                  id="non"
                  type="radio"
                  defaultChecked={data["Souhaitez-vous être garantie par une indemnité journalière en cas d’hospitalisation suite à maladie ou accident ?"] === "non" ? true : false}

                />
                
                Non
              </Label>

              <Label className={classes.btnradio}>
                <Input
                  value="oui"
                  onClick={handleChange}
                  name="Souhaitez-vous être garantie par une indemnité journalière en cas d’hospitalisation suite à
            maladie ou accident ?"
                  id="oui"
                  type="radio"
                  defaultChecked={data["Souhaitez-vous être garantie par une indemnité journalière en cas d’hospitalisation suite à maladie ou accident ?"] === "oui" ? true : false}
                />{" "}
                Oui
              </Label>
            </Col>
          </div>

          <Col xs="12" className={classes.title}>
            <h6>
              Une indemnité de 40€ en cas d’hospitalisation qui est doublée en
              cas d’accident vous parait-elle suffisante ?
            </h6>
          </Col>
          <div style={{ display: "flex", marginLeft: "2rem" }}>
            <Col xs="12" className={classes.title}>
              <Label className={classes.btnradio}>
                <Input
                  value="non"
                  onClick={handleChange}
                  name="Une indemnité de 40€ en cas d’hospitalisation qui est doublée en cas d’accident vous parait-elle
                suffisante ?"
                  id="non"
                  type="radio"
                  defaultChecked={data["Une indemnité de 40€ en cas d’hospitalisation qui est doublée en cas d’accident vous parait-elle suffisante ?"] === "non" ? true : false}

                />{" "}
                Non
              </Label>

              <Label className={classes.btnradio}>
                <Input
                  value="oui"
                  onClick={handleChange}
                  name="Une indemnité de 40€ en cas d’hospitalisation qui est doublée en cas d’accident vous parait-elle
                suffisante ?"
                  id="oui"
                  type="radio"
                  defaultChecked={data["Une indemnité de 40€ en cas d’hospitalisation qui est doublée en cas d’accident vous parait-elle suffisante ?"] === "oui" ? true : false}
                />{" "}
                Oui
              </Label>
            </Col>
          </div>
          <Col xs="12" className={classes.title}>
            <h6>
              Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?
            </h6>
          </Col>
          <div style={{ display: "flex", marginLeft: "2rem" }}>
            <Col xs="12" className={classes.title}>
              <Label className={classes.btnradio}>
                <Input
                  value="non"
                  // onClick={(e) => {
                  //   handleChange(e);
                  
                  //     Swal.fire({
                  //       icon: "warning", 
                  //       text: "Vous ne disposez pas des droits pour enregistrer ce contrat, Motif : 'curatelle' , veuillez contacter AS Solutions",
                  //       confirmButtonColor: '#3085d6'
                  //     });
                    
                  // }}
                  onClick={handleChange}
                  name="Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?"
                  id="non"
                  type="radio"
                  defaultChecked={data["Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?"] === "non" ? true : false}

                />{" "}
                Non
              </Label>

              <Label className={classes.btnradio}>
                <Input
                  value="oui"
                  onClick={handleChange}
                  name="Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?"
                  id="oui"
                  type="radio"
                  defaultChecked={data["Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?"] === "oui" ? true : false}
                />{" "}
                Oui
              </Label>
            </Col>
          </div>        
          <Col xs="12" className={classes.title}>
            <h6>êtes vous une personne politiquement exposée ?</h6>
          </Col>
          <div style={{ display: "flex", marginLeft: "2rem" }}>
            <Col xs="12" className={classes.title}>
              <Label className={classes.btnradio}>
                <Input
                  value="non"
                  // onClick={(e) => {
                  //   handleChange(e);
                  //     Swal.fire({
                  //       icon: "warning",
                  //       text: "Vous ne disposez pas des droits pour enregistrer ce contrat, Motif : 'personne politiquement exposée' , veuillez contacter AS Solutions",
                  //       confirmButtonColor: '#3085d6'
                  //     });
                    
                  // }}
                  onClick={handleChange}
                  name="êtes vous une personne politiquement exposée ?"
                  id="non"
                  type="radio"
                  defaultChecked={data["êtes vous une personne politiquement exposée ?"] === "non" ? true : false}

                />{" "}
                Non
              </Label>

              <Label className={classes.btnradio}>
                <Input
                  value="oui"
                  onClick={handleChange}
                  name="êtes vous une personne politiquement exposée ?"
                  id="oui"
                  type="radio"
                  defaultChecked={data["êtes vous une personne politiquement exposée ?"] === "oui" ? true : false}
                />{" "}
                Oui
              </Label>
            </Col>
          </div>
          <Col xs="12" className={classes.title}>
            <h6>
              Vous déclarez gérer vous-même vos papiers sans une aide extérieure
              ?
            </h6>
          </Col>
          <div style={{ display: "flex", marginLeft: "2rem" }}>
            <Col xs="12" className={classes.title}>
              <Label className={classes.btnradio}>
                <Input
                  value="non"
                  onClick={handleChange}
                  name="Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?"
                  id="non"
                  type="radio"
                  defaultChecked={data["Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?"] === "non" ? true : false}

                />{" "}
                Non
              </Label>

              <Label className={classes.btnradio}>
                <Input
                  value="oui"
                  onClick={handleChange}
                  name="Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?"
                  id="oui"
                  type="radio"
                  defaultChecked={data["Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?"] === "oui" ? true : false}
                />{" "}
                Oui
              </Label>
            </Col>
          </div>

          <Row>
            <Col xs="12" className={classes.title}>
              <h6>
                Vous ou l’un des assurés au contrat a-t’il une hospitalisation
                programmée ?
              </h6>
            </Col>
            <div style={{ display: "flex", marginLeft: "2rem" }}>
              <Col xs="12" className={classes.title}>
                <Label className={classes.btnradio}>
                  <Input
                    value="non"
                    onClick={handleChange}
                    name="Vous ou l’un des assurés au contrat a-t’il une hospitalisation programmée ?"
                    id="non"
                    type="radio"
                    defaultChecked={data["Vous ou l’un des assurés au contrat a-t’il une hospitalisation programmée ?"] === "non" ? true : false}

                  />{" "}
                  Non
                </Label>

                <Label className={classes.btnradio}>
                  <Input
                    value="oui"
                    onClick={handleChange}
                    name="Vous ou l’un des assurés au contrat a-t’il une hospitalisation programmée ?"
                    id="oui"
                    type="radio"
                    defaultChecked={data["Vous ou l’un des assurés au contrat a-t’il une hospitalisation programmée ?"] === "oui" ? true : false}
                  />{" "}
                  Oui
                </Label>
              </Col>
            </div>
          </Row>
          {data[
            "Vous ou l’un des assurés au contrat a-t’il une hospitalisation programmée ?"
          ] === "oui" ? (
            <div style={{ marginLeft: "2rem" }}>
              <div className={classes.choixSport}>
                <h6>
                  Si oui, sachez que le contrat est assorti d’une PÉRIODE
                  D’ATTENTE
                </h6>
              </div>
              <div className={classes.choixSport}>
                <h6>
                  Les garanties prennent effet 90 jours francs après la date
                  d’effet du Contrat, sous réserve du paiement de la première
                  cotisation, en cas d’Hospitalisation faisant suite à une
                  Maladie.
                </h6>
              </div>
              <div className={classes.choixSport}>
                <h6>
                  Ce délai d’attente est porté à 12 mois en cas de maternité et
                  à 18 mois pour les Accidents ou Maladies dont la première
                  constatation est antérieure à la prise d’effet du Contrat.
                </h6>
              </div>
            </div>
          ) : (
            ""
          )}

          <Col xs="12" className={classes.title}>
            <h6>Avez-vous déjà ce type d’indemnité ?</h6>
          </Col>
          <div style={{ display: "flex", marginLeft: "2rem" }}>
            <Col xs="12" className={classes.title}>
              <Label className={classes.btnradio}>
                <Input
                  value="non"
                  onClick={handleChange}
                  name="Avez-vous déjà ce type d’indemnité ?"
                  id="non"
                  type="radio"
                  defaultChecked={data["Avez-vous déjà ce type d’indemnité ?"] === "non" ? true : false}

                />
                Non
              </Label>

              <Label className={classes.btnradio}>
                <Input
                  value="oui"
                  onClick={handleChange}
                  name="Avez-vous déjà ce type d’indemnité ?"
                  id="oui"
                  type="radio"
                  defaultChecked={data["Avez-vous déjà ce type d’indemnité ?"] === "oui" ? true : false}
                />{" "}
                Oui
              </Label>
            </Col>
          </div>
          {data["Avez-vous déjà ce type d’indemnité ?"] === "oui" ? (
            <Row>
              <Col lg={"12"} md={12} style={{ margin: "0.4rem 0" }}>
                <Label for="input">quel montant ?</Label>
                <Input
                  id="input"
                  onChange={handleChange}
                  name="quel monatnt?"
                  value={data["quel monatnt?"]}
                />
              </Col>
              <Col lg={"12"} md={12} style={{ margin: "0.4rem 0" }}>
                <Label for="input">quel assureur ?</Label>
                <Input
                  id="input"
                  onChange={handleChange}
                  name="quel assureur?"
                  value={data["quel assureur?"]}
                />
              </Col>
              <Col xs="12" className={classes.title}>
                <h6>
                  Souhaitez-vous que nous nous chargions d'expédier, en votre
                  nom et pour votre compte, la lettre de résiliation ?
                </h6>
              </Col>
              <div style={{ display: "flex", marginLeft: "2rem" }}>
                <Col xs="12" className={classes.title}>
                  <Label className={classes.btnradio}>
                    <Input
                      value="non"
                      onClick={handleChange}
                      name="Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation ?"
                      id="non"
                      type="radio"
                      defaultChecked={data["Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation ?"] === "non" ? true : false}

                    />{" "}
                    Non
                  </Label>

                  <Label className={classes.btnradio}>
                    <Input
                      value="oui"
                      onClick={handleChange}
                      name="Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation ?"
                      id="oui"
                      type="radio"
                      defaultChecked={data["Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation ?"] === "oui" ? true : false}
                    />{" "}
                    Oui
                  </Label>
                </Col>
              </div>
            </Row>
          ) : (
            ""
          )}
          <Col xs="12" className={classes.title}>
            <h6>Vous ou l'un des assurés pratique-t-il un sport ?</h6>
          </Col>
          <div style={{ display: "flex", marginLeft: "2rem" }}>
            <Col xs="12" className={classes.title}>
              <Label className={classes.btnradio}>
                <Input
                  value="non"
                  onClick={handleChange}
                  name="Vous ou l'un des assurés pratique-t-il un sport ?"
                  id="non"
                  type="radio"
                  defaultChecked={data["Vous ou l'un des assurés pratique-t-il un sport ?"] === "non" ? true : false}

                />
                Non
              </Label>

              <Label className={classes.btnradio}>
                <Input
                  value="oui"
                  onClick={handleChange}
                  name="Vous ou l'un des assurés pratique-t-il un sport ?"
                  id="oui"
                  type="radio"
                  defaultChecked={data["Vous ou l'un des assurés pratique-t-il un sport ?"] === "oui" ? true : false}
                />{" "}
                Oui
              </Label>
            </Col>
          </div>
        </Row>
        {data["Vous ou l'un des assurés pratique-t-il un sport ?"] === "oui" ? (
          <Row>
            <Col xs="12" className={classes.title}>
              <h6>
                Vous ou l'un des assurés pratique-t-il l’un des sports suivants,
                qui ne sont pas garantis :
              </h6>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                id="Sports non représentés par une fédération reconnue par le ministère français"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                type="checkbox"
                value="Sports non représentés par une fédération reconnue par le ministère français"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Sports non représentés par une fédération reconnue par le ministère français") === true ? true : false}
              />
              <label htmlFor="Sports non représentés par une fédération reconnue par le ministère français">
                Sports non représentés par une fédération reconnue par le
                ministère français
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Base jump, sky flying, sky surfing, saut à l’élastique, zorbing, parapente, parachute"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                value="Base jump, sky flying, sky surfing, saut à l’élastique, zorbing, parapente, parachute"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Base jump, sky flying, sky surfing, saut à l’élastique, zorbing, parapente, parachute") === true ? true : false}
              />
              <label htmlFor="Base jump, sky flying, sky surfing, saut à l’élastique, zorbing, parapente, parachute">
                Base jump, sky flying, sky surfing, saut à l’élastique, zorbing,
                parapente, parachute
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Sports exercés à titre professionnel ou sous contrat rémunéré,"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                value="Sports exercés à titre professionnel ou sous contrat rémunéré,"
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Sports exercés à titre professionnel ou sous contrat rémunéré,") === true ? true : false}
              />
              <label htmlFor="Sports exercés à titre professionnel ou sous contrat rémunéré,">
                Sports exercés à titre professionnel ou sous contrat rémunéré,
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="BRaids, concours, expéditions…"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                value="BRaids, concours, expéditions…"
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("BRaids, concours, expéditions…") === true ? true : false}
              />
              <label htmlFor="BRaids, concours, expéditions…">
                BRaids, concours, expéditions…
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Sports en compétition"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                value="Sports en compétition"
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Sports en compétition") === true ? true : false}
              />
              <label htmlFor="Sports en compétition">
                Sports en compétition
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="VTT de descente, bicross, freeride"
                value="VTT de descente, bicross, freeride"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("VTT de descente, bicross, freeride") === true ? true : false}
              />
              <label htmlFor="VTT de descente, bicross, freeride">
                VTT de descente, bicross, freeride
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Tout sport ou activité nécessitant un Véhicule Terrestre Moteur (VTM),"
                value="Tout sport ou activité nécessitant un Véhicule Terrestre Moteur (VTM),"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Tout sport ou activité nécessitant un Véhicule Terrestre Moteur (VTM),") === true ? true : false}
              />
              <label htmlFor="Tout sport ou activité nécessitant un Véhicule Terrestre Moteur (VTM),">
                Tout sport ou activité nécessitant un Véhicule Terrestre Moteur
                (VTM),
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Sport aérien (à voile, moteur ou aile)"
                value="Sport aérien (à voile, moteur ou aile)"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Sport aérien (à voile, moteur ou aile)") === true ? true : false}
              />
              <label htmlFor="Sport aérien (à voile, moteur ou aile)">
                Sport aérien (à voile, moteur ou aile)
              </label>
              Pratiquez
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Sport nautique avec engin maritime à moteur"
                value="Sport nautique avec engin maritime à moteur"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Sport nautique avec engin maritime à moteur") === true ? true : false}
              />
              <label htmlFor="Sport nautique avec engin maritime à moteur">
                Sport nautique avec engin maritime à moteur
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Sport de neige hors-piste"
                value="Sport de neige hors-piste"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Sport de neige hors-piste") === true ? true : false}
              />
              <label htmlFor="Sport de neige hors-piste">
                Sport de neige hors-piste
              </label>
            </Col>
            {/* <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Sports de neige hors-piste"
                value="Sports de neige hors-piste"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
              />

              <label htmlFor="Sports de neige hors-piste">
                Sports de neige hors-piste
              </label>
            </Col> */}
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Sports de montagne au-delà de 2500m, de neige ou de glace sur pistes balisées uniquement
(autre que la pratique amateure sur piste de ski alpin ou de fond, du monoski et du surf ainsi que du
patinage qui est couverte),"
                value="Sports de montagne au-delà de 2500mPratiquez, de neige ou de glace sur pistes balisées uniquement
(autre que la pratique amateure sur piste de ski alpin ou de fond, du monoski et du surf ainsi que du
patinage qui est couverte),"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Sports de montagne au-delà de 2500mPratiquez, de neige ou de glace sur pistes balisées uniquement (autre que la pratique amateure sur piste de ski alpin ou de fond, du monoski et du surf ainsi que du patinage qui est couverte),") === true ? true : false}
                
              />
              <label
                value="Sports de montagne au-delà de 2500m, de neige ou de glace sur pistes balisées uniquement
(autre que la pratique amateure sur piste de ski alpin ou de fond, du monoski et du surf ainsi que du
patinage qui est couverte),"
              >
                Sports de montagne au-delà de 2500m, de neige ou de glace sur
                pistes balisées uniquement (autre que la pratique amateure sur
                piste de ski alpin ou de fond, du monoski et du surf ainsi que
                du patinage qui est couverte),
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Activité sportive maritime au-delà de six milles des côtes"
                value="Activité sportive maritime au-delà de six milles des côtes"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Activité sportive maritime au-delà de six milles des côtes") === true ? true : false}
                
              />
              <label htmlFor="Activité sportive maritime au-delà de six milles des côtes">
                Activité sportive maritime au-delà de six milles des côtes
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Plongée au-delà de 40m"
                value="Plongée au-delà de 40m"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Plongée au-delà de 40m") === true ? true : false}
              />
              <label htmlFor="Plongée au-delà de 40m">
                Plongée au-delà de 40m
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Activités sportives fluviales (rafting, canyoning…)"
                value="Activités sportives fluviales (rafting, canyoning…)"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Activités sportives fluviales (rafting, canyoning…)") === true ? true : false}
              />
              <label htmlFor="Activités sportives fluviales (rafting, canyoning…)">
                Activités sportives fluviales (rafting, canyoning…)
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Activités sportives souterraines, spéléologie"
                value="Activités sportives souterraines, spéléologie"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Activités sportives souterraines, spéléologie") === true ? true : false}
              />
              <label htmlFor="Activités sportives souterraines, spéléologie">
                Activités sportives souterraines, spéléologie
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Sports de combat (toutes formes de boxe, catch, free fight, full contact, pancrace, karaté,
capoeira, jujitsu, taekwondo, muay thai, MMA,…)"
                value="Sports de combat (toutes formes de boxe, catch, free fight, full contact, pancrace, karaté,
  capoeira, jujitsu, taekwondo, muay thai, MMA,…)"
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Sports de combat (toutes formes de boxe, catch, free fight, full contact, pancrace, karaté, capoeira, jujitsu, taekwondo, muay thai, MMA,…)") === true ? true : false}
              />
              <label
                value="Sports de combat (toutes formes de boxe, catch, free fight, full contact, pancrace, karaté,
capoeira, jujitsu, taekwondo, muay thai, MMA,…)"
              >
                Sports de combat (toutes formes de boxe, catch, free fight, full
                contact, pancrace, karaté, capoeira, jujitsu, taekwondo, muay
                thai, MMA,…)
              </label>
            </Col>
            <Col xs="12" className={classes.title}>
              <Input
                className={classes.selectInput}
                type="checkbox"
                id="Sports équestres (autre que la randonnée, le dressage, le travail à pied, le manège)."
                value="Sports équestres (autre que la randonnée, le dressage, le travail à pied, le manège)."
                name="Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"
                onChange={sportsSansGaranties}
                defaultChecked={data["Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :"].includes("Sports équestres (autre que la randonnée, le dressage, le travail à pied, le manège).") === true ? true : false}
              />
              <label htmlFor="Sports équestres (autre que la randonnée, le dressage, le travail à pied, le manège).">
                Sports équestres (autre que la randonnée, le dressage, le
                travail à pied, le manège).
              </label>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col xs="12" className={classes.title}>
            <h6>
              Vous avez déclaré ne pas pratiquer de discipline sportive exclue
              aux conditions générales du contrat
            </h6>
          </Col>
          <div style={{ display: "flex", marginLeft: "2rem" }}>
            <Col xs="12" className={classes.title}>
              <Label className={classes.btnradio}>
                <Input
                  value="non"
                  onClick={handleChange}
                  name="Vous avez déclaré ne pas pratiquer de discipline sportive exclue aux conditions générales du contrat"
                  id="non"
                  type="radio"
                  defaultChecked={data["Vous avez déclaré ne pas pratiquer de discipline sportive exclue aux conditions générales du contrat"] === "non" ? true : false}

                />
                Non
              </Label>

              <Label className={classes.btnradio}>
                <Input
                  value="oui"
                  onClick={handleChange}
                  name="Vous avez déclaré ne pas pratiquer de discipline sportive exclue aux conditions générales du contrat"
                  id="oui"
                  type="radio"
                  defaultChecked={data["Vous avez déclaré ne pas pratiquer de discipline sportive exclue aux conditions générales du contrat"] === "oui" ? true : false}
                />{" "}
                Oui
              </Label>
            </Col>
          </div>
        </Row>
      </Form>
      <Row style={{ marginTop: "2rem" }}>
        <Col className={classes.RetourCol}>
          <Button
            className={classes.retourButton}
            style={{
              backgroundColor: retourButtonColor,
            }}
            onClick={() => navigate("/Proposition")}
          >
            {" "}
            <FontAwesomeIcon
              style={{ marginRight: "0.5rem" }}
              icon={faArrowLeft}
            />
            Retour
          </Button>
        </Col>
        <Col>
          <Button
            className={classes.confirmButton}
            style={{
              backgroundColor: confirmButtonColor,
            }}
            onClick={confirmedBtn}
          >
            Confirmer{" "}
            <FontAwesomeIcon
              style={{ marginLeft: "0.5rem" }}
              icon={faArrowRight}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default FicheConseil;
