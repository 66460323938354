import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import ParcoursContext from "../../../Context/ParcoursContext";
import Familymember from "./Familymember/Familymember";
import classes from "./FamilyComposition.module.css";
import addConjoint from "../../../assets/images/addConjoint.png";
import addChild from "../../../assets/images/icon-ajouter-enfant.png";

function FamilyComposition() {
  const { souscripteur, setSouscripteur, addConjointColor, addChildColor } =
    React.useContext(ParcoursContext);

  const onAddChild = () => {
    let souscripteurs = souscripteur;
    let temp = [...souscripteurs];
    let nb = souscripteurs.filter(
      (item) => item.relationship === "children"
    )?.length;
    if(nb<3){   
    temp.push({
      nom: "",
      prenom: "",
      dn: "",
      civility:"",
      relationship: "children",
      relationship_rank: 11 + nb,
      primary_subscriber: false,
    });
    setSouscripteur(temp);
  }   
  };
  const onAddConjoint = () => {
    let souscripteurs = souscripteur;
    let temp = [...souscripteurs];
    !temp.find((item) => item.relationship === "married") &&
      temp.push({
        nom: "",
        prenom: "",
        dn: "",
        civility:"",
        relationship: "married",
        relationship_rank: 2,
        primary_subscriber: false,
      });
    setSouscripteur(temp);
  };
  return (
    <Container className={classes.memberRow}>
      <Row style={{ paddingTop: "1rem" }}>
        <Col xs="12" lg="6">
          <h4 className={classes.title}>Composition Familiale</h4>
        </Col>
        <Col xs="12" lg="6" className={classes.newChildButton}>
          <Button
            className={classes.conjointButton}
            style={{
              backgroundColor: addConjointColor,
            }}
            onClick={onAddConjoint}
          >
            <img
              src={addConjoint}
              alt="add"
              style={{
                backgroundColor: addConjointColor,
              }}
            />{" "}
            Ajouter Conjoint
          </Button>
          <Button
            className={classes.addChildButton}
            style={{
              backgroundColor: addChildColor,
            }}
            onClick={onAddChild}
          >
            <img src={addChild} alt="add" /> Ajouter un enfant
          </Button>
        </Col>
      </Row>
      <Row>
        
        {souscripteur
          .filter((item) => item.relationship === "married")
          ?.map((nb, index) => {
            return (
              <Col xs="12" lg="6" key={index}>
                <Familymember
                  souscripteur={souscripteur}
                  setSouscripteur={setSouscripteur}
                  count={1}
                  name="Conjoint(e)"
                  item={nb}
                  dn={nb?.dn}
                  nom={nb?.nom}
                  prenom={nb?.prenom}
                  civility={nb?.civility}
                  
                />
              </Col>
            );
          })}

        {souscripteur
          .filter((item) => item.relationship === "children")
          ?.map((nb, index) => {
            return (
              <Col xs="12" lg="6" key={index}>
                <Familymember
                  souscripteur={souscripteur}
                  setSouscripteur={setSouscripteur}
                  count={index + 2}
                  name={`Enfant ${index + 1}`}
                  item={nb}
                  dn={nb?.dn}
                  nom={nb?.nom}
                  prenom={nb?.prenom}
                  civility={nb?.civility}
                />
              </Col>
            );
          })}
      </Row>
    </Container>
  );
}

export default FamilyComposition;
