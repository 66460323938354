import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InformationsBancaires from "./pages/InformationsBancaires/InformationsBancaires";
import React from "react";
import UserInformation from "./pages/UserInformation/UserInformation";
import Proposition from "./pages/Proposition/Proposition";
import Context from "./Context/Context";
import Success from "./pages/Success/Success";
import Maintenance from "./pages/Maintenance/Maintenance";
import FicheConseil from "./pages/FicheConseil/FicheConseil";

function App() {
  return (
    <Context>
      <BrowserRouter>
        <Routes>
          <Route path="">
            <Route path="" element={<UserInformation />} />
            <Route path="/Proposition" element={<Proposition />} />
            <Route
              path="/Proposition/InformationBancaires"
              element={<InformationsBancaires />}
            />
            <Route
              path="/Proposition/InformationBancaires/Success"
              element={<Success />}
            />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/fiche" element={<FicheConseil />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Context>
  );
}

export default App;
