import axios from "axios";
import React from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import ParcoursContext from "../../../Context/ParcoursContext";

import classes from "./Souscripteur.module.css";
function Souscripteur() {
  const { souscripteur, setSouscripteur } = React.useContext(ParcoursContext);
  const [villeOptions, setVilleOptions] = React.useState([]);
  const [free, setFree] = React.useState(true);

  React.useEffect(() => {
    if (souscripteur[0].ville) {
      getVilles(souscripteur[0].cp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVilles = (val) => {
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/groupe_villes/get_villes_by_code_postal`,
        {
          postal_code: val,
        }
      )
      .then((res) => {
        setVilleOptions(res.data.villes);
        setFree(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Form className={classes.form}>
      <h4 className={classes.souscripteurTitle}>Souscripteur</h4>
      <Row>
        <Col xs="12" md="12" lg="6">
          <Label for="civilté" className={classes.inputTitle}>
            Civilité
            <span>*</span>
          </Label>
          <Input
            type="select"
            id="civilité"
            value={souscripteur[0]?.civility}
            onChange={(e) => {
              let news = [...souscripteur];
              news[0].civility = e.target.value;
              setSouscripteur(news);
            }}
          >
            <option value=""></option>
            <option value="Madame">Mme</option>
            <option value="Monsieur">M</option>
          </Input>
        </Col>
        <Col xs="12" md="12" lg="6">
          <Label for="nom" className={classes.inputTitle}>
            Nom <span>*</span>
          </Label>
          <Input
            name="email"
            id="nom"
            required
            value={souscripteur[0]?.nom}
            onChange={(e) => {
              let news = [...souscripteur];
              news[0].nom = e.target.value;
              setSouscripteur(news);
            }}
          />
        </Col>
        <Col xs="12" md="12" lg="6">
          <Label className={classes.inputTitle} for="Prenom">
            Prénom <span>*</span>
          </Label>
          <Input
            name="Prenom"
            id="exampleEmail"
            required
            value={souscripteur[0]?.prenom}
            onChange={(e) => {
              let news = [...souscripteur];
              news[0].prenom = e.target.value;
              setSouscripteur(news);
            }}
          />
        </Col>

        <Col xs="12" md="12" lg="6">
          <Label className={classes.inputTitle} for="Anneé">
            Date de naissance <span>*</span>
          </Label>
          <Input
            type="date"
            name="select"
            id="Anneé"
            required
            value={souscripteur[0]?.dn}
            onChange={(e) => {
              let news = [...souscripteur];
              news[0].dn = e.target.value;
              setSouscripteur(news);
            }}
          />
        </Col>

        <Col xs="12" md="12" lg="6">
          <Label
            className={classes.inputTitle}
            for="adresse_assure||Adresse||3"
          >
            Adresse <span> * </span>
          </Label>
          <Input
            placeholder=""
            name="adresse_assure||Adresse||3"
            value={souscripteur[0].adresse}
            onChange={(e) => {
              let news = [...souscripteur];
              news[0].adresse = e.target.value;
              setSouscripteur(news);
            }}
          />
        </Col>

        <Col xs="12" md="12" lg="6">
          <Label className={classes.inputTitle} for="cp_assure||Code postal||4">
            Code postal <span> * </span>
          </Label>
          
          <Input
            type="number"
            value={souscripteur[0].cp}
            name="cp_assure||Code postal||4"
            onChange={(e) => {
              if (e.target.value.length >= 5) {
                getVilles(e.target.value);
              } else {
                setFree(true);
              }
              let news = [...souscripteur];
              news[0].cp = e.target.value;
              setSouscripteur(news);
            }}
          />
        </Col>
        <Col xs="12" md="12" lg="6">
          <Label className={classes.inputTitle} for="ville_assure||Ville||5">
            Ville <span> * </span>
          </Label>
          <Input
            type="select"
            value={souscripteur[0].ville}
            name="ville_assure||Ville||5"
            disabled={free}
            onChange={(e) => {
              if (free !== true) {
                let news = [...souscripteur];
                let ville_selected = villeOptions.filter(
                  (element) => element.nom_comm === e.target.value
                );
                news[0].code_insee = ville_selected[0]?.id;
                news[0].ville = e.target.value;
                setSouscripteur(news);
              }
            }}
          >
            <option></option>
            {villeOptions?.map((val, idx) => (
              <option key={idx} value={val.nom_comm}>
                {val.nom_comm}
              </option>
            ))}
          </Input>
        </Col>
        <Col xs="12" md="12" lg="6">
          <Label className={classes.inputTitle} for="Anneé">
            E-mail <span> * </span>
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={souscripteur[0]?.mail}
            onChange={(e) => {
              let news = [...souscripteur];
              news[0].mail = e.target.value;
              setSouscripteur(news);
            }}
          />
        </Col>
        <Col xs="12" md="12" lg="6">
          <Label className={classes.inputTitle} for="tel">
            numéro de téléphone <span> * </span>
          </Label>
          <div style={{ display: "flex" }}>
            <Input
              onChange={(e) => {
                let news = [...souscripteur];
                news[0].country_prefix = e.target.value;
                setSouscripteur(news);
              }}
              name="prefix_mobile_assure"
              value={souscripteur[0].country_prefix}
              style={{ width: "20%", marginRight: "1rem" }}
              placeholder={"+33"}
            />
            <Input
              value={souscripteur[0]?.phone}
              onChange={(e) => {
                let news = [...souscripteur];
                news[0].phone = e.target.value;
                setSouscripteur(news);
              }}
              type="text"
              name="tel"
              id="tel"
            />
          </div>
        </Col>
      </Row>
      <br />
      <h5 className={classes.titre}>
        Les champs avec <span> * </span> sont obligatoires
      </h5>
    </Form>
  );
}

export default Souscripteur;
